import {
  Table,
  message,
  Button,
  Drawer,
  Select,
  Input,
  DatePicker,
  Form,
  Tag,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { getAgentBody } from "../lib/apis";
import { useSelector } from "react-redux";
import moment from "moment";
import "../style/ball.css";
import dayjs from "dayjs";

const { Search } = Input;
const { Text } = Typography;

export default function AgentMaungPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [loading, setLoading] = useState(false);
  const [betLists, setBetLists] = useState([]);
  const [keyTeam, selectKeyTeam] = useState();
  const [oneKeyWord, setOneKeyWord] = useState(null);
  const [twoKeyWord, setTwoKeyWord] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [visible, setVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const showDrawer = (record) => {
    console.log("rc", record);
    setCurrentRecord(record);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setCurrentRecord(null);
  };

  function findKeyTeam(value) {
    const teams = [value.teamOne, value.teamTwo];
    const filterKeyTeam = teams.filter((team) => team.id == value.team_id);
    selectKeyTeam(...filterKeyTeam);
  }

  const columns = [
    {
      title: "User Info",
      key: "user_info",
      render: (text, record) => <>{record.user.user_code}</>,
    },
    {
      title: "Bet Time",
      key: "bet_time",
      render: (text, record) => (
        <>
          {moment(record.created_at).format("MMM DD Y")}
          <br />
          {moment(record.created_at).format("hh:mm A")}
        </>
      ),
    },
    {
      title: "Bit Amount",
      dataIndex: "bit_amount",
      key: "bit_amount",
    },
    // {
    //   title: "Is Finished?",
    //   dataIndex: "is_finish",
    //   key: "is_finish",
    //   render: (text) => (text ? "Yes" : "No"),
    // },
    {
      title: "Is Finished?",
      key: "is_finish",
      dataIndex: "is_finish",
      render: (text, record) => {
        const isFinished = record.is_finish;
        const tagColor = isFinished ? "success" : "error";
        const tagText = isFinished ? "Yes" : "No";

        return (
          <Tag color={tagColor} style={{ text }}>
            <Text
              style={{ FontSize: "15px", color: isFinished ? "green" : "red" }}
            >
              {tagText}
            </Text>
          </Tag>
        );
      },
    },
    {
      title: "Is Win?",
      key: "is_win",
      dataIndex: "is_win",
      render: (_, record) => {
        const isFinished = record.is_finish;
        if (!isFinished) {
          return <Text style={{ fontSize: "15px" }}>-</Text>;
        }
        const isWin = record.is_win;
        const tagText = isWin ? "နိုင်" : "ရှုံး";

        return (
          <Text style={{ FontSize: "15px", color: isWin ? "green" : "red" }}>
            {tagText}
          </Text>
        );
      },
    },
    {
      title: "Updated Time",
      key: "updated_time",
      render: (text, record) => (
        <>
          {moment(record.updated_at).format("MMM DD Y")}
          <br />
          {moment(record.updated_at).format("hh:mm A")}
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button type="primary" onClick={() => showDrawer(record)}>
          Detail
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getBetBodyLists();
  }, []);

  async function getBetBodyLists(page = 1, endDate = "") {
    setLoading(true);
    try {
      const res = await getAgentBody(
        `${process.env.REACT_APP_SERVER_API}/v1/agents/bet/maung_lists?page=${page}&perPage=10&endDate=${endDate}`,
        access_token
      );
      const data = res.data;
      setLoading(false);
      setBetLists(data.data);
      setTotal(data.total);
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.error || "Connection Error");
    }
  }

  const onFinish = (value) => {
    const { searchTerm, endDate } = value;
    const formatDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";

    getBetBodyLists(1, formatDate);
  };

  return (
    <>
      <div className="flex flex items-center justify-between ">
        <h4>Maung History</h4>
        <Form
          layout="inline"
          onFinish={onFinish}
          clear
          style={{ marginBottom: 16 }}
        >
          <Form.Item name="searchTerm">
            <Input
              placeholder="Search User Code"
              allowClear
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item name="endDate">
            <DatePicker
              placeholder="Select Updated Date"
              style={{ width: 200 }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={betLists}
        rowKey={(record) => record.id}
        pagination={{
          responsive: true,
          current: pageNumber,
          total: total,
          onChange: (pageNumber) => {
            setPageNumber(pageNumber);
            getBetBodyLists(pageNumber);
          },
        }}
      />

      <Drawer
        title="အသေးစိတ် အချက်လက်များ"
        placement="right"
        onClose={onClose}
        visible={visible}
        width={500}
      >
        {currentRecord && (
          <div>
            <div className="mb-3r">
              <p className="text-success">
                ID <span>:</span>
                <small className="text-white ms-3"> {currentRecord.id}</small>
              </p>
              <p className="text-success">
                Game Type <span>:</span>
                <span className="text-white ms-3">Maung</span>
              </p>
              <p className="text-success">
                Tax <span>:</span>
                <span className="text-white ms-3">
                  {currentRecord.win_cut_percent}
                </span>
              </p>
              <p className="text-success">
                User Code <span>:</span>
                <span className="text-white ms-3">
                  {" "}
                  {currentRecord.user.user_code}
                </span>
              </p>
              <p className="text-success">
                Login Name <span>:</span>
                <span className="text-white ms-3">
                  {" "}
                  {currentRecord.user.login_name}
                </span>
              </p>

              <p className="text-success">
                Is Finish? <span>:</span>
                <span className="text-white ms-3">
                  {" "}
                  {currentRecord.is_finish ? "YES" : "NO"}
                </span>
              </p>

              <p className="text-success">
                Is Win? <span>:</span>
                <span className="text-white ms-3">
                  {" "}
                  {currentRecord.is_win == true ? "နိုင်" : "ရှုံး"}
                </span>
              </p>
              <p className="text-success">
                Match Cancel <span>:</span>
                <span className="text-white ms-3">
                  {" "}
                  {currentRecord.cancel_match ? "Yes" : "No"}
                </span>
              </p>
            </div>

            <p className="text-success mb-4">
              Final Point <span>:</span>
              <span className="text-white ms-3">
                {" "}
                {currentRecord.cancel_match
                  ? currentRecord.bit_amount
                  : currentRecord.final_point}
              </span>
            </p>

            <p className="text-success mb-4">
              Bit Amount <span>:</span>
              <span className="text-white ms-3">
                {" "}
                {currentRecord.bit_amount}
              </span>
            </p>

            <p className="text-success mb-4">
              Bet Time <span>:</span>
              <span className="text-white ms-3">
                {" "}
                {moment(currentRecord.created_at).format("hh:mm A")}
              </span>
            </p>

            <p className="text-success mb-4">
              Updated_AT <span>:</span>
              <span className="text-white ms-3">
                {" "}
                {moment(currentRecord.updated_at).format("hh:mm A")}
              </span>
            </p>
          </div>
        )}

        {currentRecord &&
          currentRecord.bet_maung.map((item) => {
            console.log("item:", item);
            let keyTeam =
              item.match.key_team == item.teamOne.id
                ? item.teamOne.name_en
                : item.teamTwo.name_en;

            let choiceTeam =
              item.team_id == item.teamOne.id
                ? item.teamOne.name_en
                : item.teamTwo.name_en;

            const keyOddChanges = () => {
              console.log(item?.match.key);
              const keys = [item.key_one, item.key_two, item.key_three];
              const validKeys = keys.every((key) => key !== null);
              return validKeys ? keys.join("") : item?.match.key;
            };

            const goalOddChanges = () => {
              const goals = [
                item.goal_plus_one,
                item.goal_plus_two,
                item.goal_plus_three,
              ];
              const validGoals = goals.every((key) => key !== null);
              return validGoals ? goals.join("") : item?.match.goal_plus;
            };

            return (
              <div
                className="text-white rounded-2xl mt-5 bg-card py-5"
                key={item.id}
              >
                <div style={{ marginBottom: "20px", marginLeft: "20px" }}>
                  {moment(item.match.match_time).format("hh:mm A")}
                </div>

                <div className="font-semibold flex items-center justify-around">
                  <div>
                    <img
                      src={item?.teamOne?.logo}
                      className="object-cover mx-auto h-14 mb-4"
                    />
                    <span className="text-center me-2">
                      {item?.teamOne?.name_en || "...."}
                    </span>

                    <span className="text-center text-success">
                      {item.match.key_team === item.teamOne.id
                        ? keyOddChanges()
                        : ""}
                    </span>
                  </div>

                  <div>
                    <div className="goal-card py-1 px-2 rounded-lg mx-2 mb-4">
                      <span className="me-1">
                        {item?.match.team_one_goal || "0"}
                      </span>
                      :
                      <span className="ms-1">
                        {item?.match.team_two_goal || "0"}
                      </span>
                    </div>

                    <div className="bg-success py-1 px-2 rounded-lg mx-2">
                      <span className="me-1 text-xs">{goalOddChanges()}</span>
                    </div>
                  </div>

                  <div>
                    <img
                      src={item?.teamTwo?.logo}
                      className="object-cover mx-auto h-14 mb-4"
                    />

                    <span className="text-center me-1 text-success">
                      {item.match.key_team === item.teamTwo.id
                        ? keyOddChanges()
                        : ""}
                    </span>
                    <span className="text-center">
                      {item?.teamTwo?.name_en || "...."}
                    </span>
                  </div>
                </div>

                <hr className="my-4" />

                <div className="px-4 flex justify-between">
                  <div>
                    <p className="text-success font-semibold">Selected</p>

                    <span className="mt-2">
                      {item.bet_type === "KEY" ? choiceTeam : ""}
                    </span>

                    {item.bet_type === "GOAL_UP" &&
                    item.team_id === item.teamOne.id ? (
                      <span>GOAL_UP</span>
                    ) : (
                      ""
                    )}

                    {item.bet_type === "GOAL_UP" &&
                    item.team_id !== item.teamOne.id ? (
                      <span>GOAL_DOWN</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <p className="text-success mb-4">
                      Match Cancel <span>:</span>
                      <span
                        className="text-white"
                        style={{ paddingLeft: "4px" }}
                      >
                        {item?.match.cancel_match ? "Yes" : "No"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </Drawer>
    </>
  );
}
