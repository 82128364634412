import { useEffect } from "react";
import { useState } from "react";
import { GetDataListFromServer } from "../../lib/apis";
import { GAME_TWOD_USER_LOGS_API } from "../../lib/config";
import { QUERY_USER_TWOD_LOGS } from "../../lib/query_objects";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  message,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { moneyFormat, queryHandler } from "../../lib/globle_functions";
import dayjs from "dayjs";
import * as moment from "moment-timezone";

const { Text } = Typography;

export default function TwodUserLogsDetail({ data, closeDrawer }) {
  const { access_token, ui_size } = useSelector((state) => state.user);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    target_time: "",
    win_lose: "",
    is_finish: "",
    target_date: "",
  });
  const [paging, setPaging] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });

  async function getList(page_number, per_page, updateFilter) {
    await GetDataListFromServer(
      `${GAME_TWOD_USER_LOGS_API}/${data.id}`,
      queryHandler(updateFilter ? updateFilter : filter, QUERY_USER_TWOD_LOGS, {
        page_number,
        per_page,
      }),
      access_token
    )
      .then(({ data: { data: dataList, total } }) => {
        updateFilter && setFilter(updateFilter);
        setPaging({ ...paging, total, page_number, per_page });
        setList(dataList);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || "Connection Error");
        setLoading(false);
      });
  }

  function clearTransaction() {
    getList(paging.page_number, paging.per_page, {
      target_time: "",
      win_lose: "",
      is_finish: "",
      target_date: "",
    });
  }

  useEffect(() => {
    getList(paging.page_number, paging.per_page);
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      render: (date) =>
        moment(date).tz("Asia/Yangon").format("YYYY-MM-DD"),
    },
    {
      title: "Target Time",
      dataIndex: "target_time",
    },
    {
      title: "Count",
      dataIndex: "count",
    },
    {
      title: "Numbers",
      dataIndex: "twod_users",
      render: (numberList) => {
        return (
          <>
            {numberList.slice(0, 6).map((value) => (
              <Text key={value.id}>{value.bit_number}, </Text>
            ))}
            {numberList.length > 6 ? "..." : ""}
          </>
        );
      },
    },
    {
      title: "Win Number",
      dataIndex: "twod_users",
      render: (userList) => (
        <Text>
          {userList.length > 0 && userList[0].is_finish
            ? userList[0].win_number
            : "---"}
        </Text>
      ),
    },
    {
      title: "Is Finish",
      dataIndex: "is_finish",
      render: (is_finish) => <Text>{is_finish ? "Yes" : "No"}</Text>,
    },
  ];

  function expendableHandler(record) {
    const columns = [
      {
        title: "Bit Number",
        dataIndex: "bit_number",
      },
      {
        title: "Amount",
        dataIndex: "bit_amount",
        render: (amount) => moneyFormat(amount),
      },
      {
        title: "Win Number",
        dataIndex: "win_number",
        render: (win_number) => (record.is_finish ? win_number : "---"),
      },
      {
        title: "Is Win",
        dataIndex: "win_lose",
        render: (win_lose) =>
          record.is_finish ? (
            <Tag color={win_lose ? "success" : "error"}>
              {win_lose ? "Win" : "Lose"}
            </Tag>
          ) : (
            "---"
          ),
      },
      {
        title: "Win Amount",
        dataIndex: "win_amount",
        render: (win_amount) =>
          record.is_finish ? moneyFormat(win_amount) : "---",
      },
    ];

    return (
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={record.twod_users}
            pagination={false}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Text style={{ color: "blue", fontSize: "2rem" }}>
              total ={" "}
              {record.twod_users
                .map((value) => value.bit_amount)
                .reduce((a, b) => a + b)}
            </Text>
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Row gutter={[12, 2]}>
            <Col span={6}>
              <Row>
                <Col span={12}>
                  <Text>Date</Text>
                </Col>
                <Col span={12}>
                  <DatePicker
                    allowClear={false}
                    style={{ with: "100%" }}
                    format={"YYYY-MM-DD"}
                    value={
                      filter.target_date !== ""
                        ? dayjs(filter.target_date, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        target_date: e,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={12}>
                  <Text>Target Time</Text>
                </Col>
                <Col span={12}>
                  <Select
                    value={filter.target_time}
                    style={{ width: "100%" }}
                    options={[
                      { label: "11:00:00", value: "11:00:00" },
                      { label: "12:01:00", value: "12:01:00" },
                      { label: "15:00:00", value: "15:00:00" },
                      { label: "16:30:00", value: "16:30:00" },
                    ]}
                    onChange={(e) => setFilter({ ...filter, target_time: e })}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={12} justify={"end"} style={{ width: "100%" }}>
                <Col>
                  <Button
                    size={ui_size}
                    loading={loading}
                    type="primary"
                    onClick={() => clearTransaction()}
                  >
                    Clear
                  </Button>
                </Col>
                <Col>
                  <Button
                    size={ui_size}
                    loading={loading}
                    type="primary"
                    onClick={() => {
                      getList(paging.page_number, paging.per_page);
                    }}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            size={ui_size}
            style={{ marginTop: "12px" }}
            columns={columns}
            rowKey={(record) => record.id}
            pagination={{
              total: paging.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getList(pageNumber, perPage);
              },
            }}
            expandable={{
              expandedRowRender: (record) => expendableHandler(record),
            }}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
    </Spin>
  );
}
